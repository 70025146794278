<template>
  <table-view
    ref="table"
    api="category"
    v-model="dialog"
    :filters="filters"
    :headers="headers"
    :popup="popup"
    :save="save"
    :edit="edit"
    :compute="compute"
    @click:item="edit( $event.item.item )"
    ignore-status
  >
  <template v-slot:item.value.picture="{ value }">

    <d-image
      :value="value"
      :width="imageDimension"
      :height="imageDimension"
      :color="value ? 'transparent' : undefined"
      item-image="url"
      hide-placeholder
      outlined
      contain
    />

  </template>
  <template v-slot:item.value.filters="{ value }">
    <template v-if="value.length">

      <category-filter
        class="ma-1"
        v-for="( filter, index ) in value"
        :key="index"
        :item="filter"
        small link
      />

    </template>
    <span v-else/>
  </template>
  <template v-slot:item.value.status="{ value }">

    <status
      :value="value"
      :block-value="blockValue"
    />

  </template>
  <template v-slot:form>

    <category-form
      ref="form"
      v-model="model"
      :is-new="isNew"
      :save="save"
    />

  </template>
  <template v-slot:footer>

    <v-spacer/>

    <btn
      class="mr-2"
      color="primary"
      :loading="exporting"
      @click.stop="exportData"
    >
      {{ $t('btn.export') }}
    </btn>

    <btn @click.stop="edit()">
      {{ $t('category.btn.new') }}
    </btn>

  </template>

  </table-view>
</template>

<script>
import TableView from '@/components/TableView';
import DImage from '@/components/Image';
import CategoryFilter from '@/components/values/CategoryFilter';
import Status from '@/components/values/Status';
import CategoryForm from './Form';
import { ICONS, CATEGORY_STATUS } from '@/utils/constants';
import { mapState, mapGetters } from 'vuex';

export default {
  name: 'Categories',
  auth: true,
  head: vm => ({ title: vm.$i18n.t('category.title') }),
  layout: 'admin',
  layoutProps: ({ i18n }) => ({
    title: i18n.t('category.title'),
    icon: ICONS.category
  }),
  components: { TableView, DImage, CategoryFilter, Status, CategoryForm },
  data: () => ({
    model: {},
    dialog: false,
    exporting: false,
    isNew: false,
    blockValue: CATEGORY_STATUS.BLOCKED,
    filters: [],
    popup: {}
  }),
  computed: {
    ...mapState( 'app', [ 'isMobile' ]),
    ...mapGetters( 'app', [ 'imageDimension' ]),
    categoryStatus() {
      return Object.values( CATEGORY_STATUS ).map( v => ({
        value: Number(v),
        text: this.$t('category.status.' + v )
      }))
    },
    headers() {
      return [
        {
          key: 'picture',
          text: this.$t('inputs.picture.label'),
          mobile: true,
          width: this.imageDimension,
          sorteable: false,
          filter: false
        },
        {
          key: 'name',
          text: this.$t('inputs.name.label'),
          mobile: true
        },
        {
          key: 'filters',
          text: this.$t('inputs.filters.label'),
          filter: {
            is: 'api-field',
            api: 'categoryFilter',
            key: 'categoryFilter',
            itemSearch: 'name',
            clearable: true,
            multiple: true
          }
        },
        {
          key: 'position',
          text: this.$t('inputs.position.label'),
          width: 1,
          align: 'center',
          filter: { is: 'number-field', int: true }
        },
        {
          key: 'status',
          text: this.$t('inputs.status.label'),
          mobile: true,
          width: 1,
          align: 'center',
          filter: {
            is: 'select-field',
            clearable: true,
            items: this.categoryStatus
          }
        }
      ];
    },
  },
  methods: {
    compute( items ) {
      return items.map( item => {
        item.filters = ( item.filters || [] ).filter( i => i.status !== -1 );
        return item;
      });
    },
    edit( item ) {

      this.dialog = true;
      this.isNew = !item;
      this.popup = {
        ...this.popup,
        title: this.$t(`category.btn.${ item ? 'edit': 'new' }`)
      };

      if ( ! this.isNew ) {
        this.$router.replace({
          path: this.$route.path,
          params: this.$route.params,
          query: { ...this.$route.query, id: item.id }
        }).catch(() => {});
      }

      this.$nextTick(() => {
        this.$refs.form.resetValidation();
        this.model = {
          id: -1,
          status: 1,
          ...item
        };
      });
    },
    sanitize( model ) {
      return {
        ...model,
        picture: undefined,
        pictureId: model.picture
          ? model.picture.id
          : null
      };
    },
    exportData() {
      if ( this.exporting ) return;
      this.exporting = true;
      this.$refs.table.exportData().finally(() => {
        this.exporting = false;
      });
    },
    save() {
      if ( this.$refs.form.validate()) {
        this.dialog = false;
        this.model = this.$refs.form.model;
        this.$store
          .dispatch('api/category/set', this.sanitize( this.model ))
          .then(() => this.$refs.table.refresh())
          .then(() => {
            this.$store.dispatch( 'app/success', {
              message: this.$t('alerts.save')
            });
          }).catch( err => {
            this.$store.dispatch( 'app/error', {
              message: err.message
            });
          });
      }
    },
  }
}
</script>
